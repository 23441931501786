a,
button {
    outline: none !important;
}

body {
    scrollbar-color: #ccc transparent;
    scrollbar-width: thin;
}

:root {
    --dark-blue: #153e7b;
    --secondary: #97b0e3;
    --color-gray-lighter: #0000000D;
    --color-error-lighter: #FF00000D;
}

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}
