div.rc-slider-rail {
    background-color: #E8E8E8 !important;
    height: 12px !important;
    border-radius: 8px !important;
}

div.rc-slider-track {
    background-color: #3C7FF9 !important;
    height: 12px !important;
    border-radius: 8px !important;
}

div.rc-slider-handle {
    width: 1.25rem;
    height: 1.25rem;
    background-color: #ffffff;
    border-radius: 50%;
    border: 0.36px solid #0000000A;
    opacity: 1;
    cursor: pointer;
    box-shadow: 0px 2.14px 0.71px 0px #0000001A;
}

div.rc-slider-step {
    height: 10px !important;
}

span.rc-slider-dot {
    width: 12px;
    height: 12px;
    background-color: #000;
    border: 0.21px solid rgba(0, 0, 0, 0.04);
    opacity: 0.22;
}

div.rc-slider-step span:first-child {
    transform: translateX(0%) !important;
}

div.rc-slider-step span:last-child {
    transform: translateX(-100%) !important;
}