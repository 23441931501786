:root {
    --toastify-color-error: #fef2f2;
    --toastify-text-color-error: #7f1d1d;
    --toastify-border-error-color: #dc2626;

    --toastify-color-success: #d1fae5;
    --toastify-text-color-success: #064e3b;
    --toastify-border-success-color: #059669;
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-color-info: #dbeafe;
    --toastify-text-color-info: #153e7b;
    --toastify-border-info-color: #2563eb;

    --toastify-color-warning: #fff7ed;
    --toastify-text-color-warning: #7c2d12;
    --toastify-border-info-warning: #ea580c;

    --toastify-toast-width: 600px;

    --toastify-color-default: #111827;
    --toastify-text-color-default: white;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
    background: var(--toastify-color-default);
    color: var(--toastify-text-color-default);
}

.Toastify__toast {
    display: flex;
    min-height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.Toastify__toast-body {
    padding: 0;
    display: flex;
    align-items: flex-start;
    word-break: break-word;
}

.Toastify__close-button {
    color: black;
    width: 10px;
    height: 10px;
}

.Toastify__toast--error {
    background: #059669;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    border: 1px solid var(--toastify-border-error-color);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    border: 1px solid var(--toastify-border-success-color);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    border: 1px solid var(--toastify-border-info-color);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    border: 1px solid var(--toastify-border-info-warning);
}

.Toastify__close-button {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin: auto;
}

.Toastify__toast > svg {
    width: 20px;
    height: 20px;
    margin-inline-start: auto;
}
