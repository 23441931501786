.mention {
    color: var(--dark-blue);
    font-weight: 600;
}

.remirror-editor-wrapper {
    padding: 0 !important;
}

.remirror-editor {
    min-height: 2.4rem !important;
    overflow: visible !important;
    padding: 0 !important;
}

.remirror-theme .ProseMirror {
    /* box-shadow: var(--secondary) 0px 0px 0px 0.1em !important; */
    box-shadow: none !important;
}

.remirror-theme .ProseMirror:focus {
    /* box-shadow: var(--secondary) 0px 0px 0px 0.2em !important; */
    box-shadow: none !important;
}

.remirror-floating-popover {
    z-index: 1000;
}

/* Placeholder styling */
.remirror-is-empty::before {
    font-style: normal !important;
}

.suggest-label {
    display: flex;
    flex-direction: column;
    justify-items: center;
}
