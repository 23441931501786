#ageFilter input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 1rem;
    height: 1rem;
    background-color: var(--dark-blue);
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

#ageFilter input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 1rem;
    height: 1rem;
    background-color: var(--dark-blue);
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

#ageFilter input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px var(--secondary), 0 0 9px var(--secondary);
    -webkit-box-shadow: inset 0 0 3px var(--secondary), 0 0 9px var(--secondary);
}
#ageFilter input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: var(--dark-blue);
    pointer-events: none;
}
